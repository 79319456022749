<template>
  <main>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="auth-header">
            <buttonLink :destination="'/'" :vegangLogo="true"> </buttonLink>
            <button-link
              :isInternal="true"
              v-if="loggedIn && userType == 'supplier'"
              :content="' Go To Supplier Dashboard'"
              :destination="'/supplier'"
              :filled="true"
            >
            </button-link>
            <button-link
              :isInternal="true"
              v-if="!loggedIn"
              :content="'Login For dropshipper'"
              :destination="'/login/retailer'"
              :outlined="true"
              :login="true"
            >
            </button-link>
          </div>
        </div>
      </div>
    </div>

    <div class="auth-page for-retailer">
      <div class="formbox">
        <div class="authpage-header">
          <h1 class="title for-retailer">
            Sign up as a <span class="green-text">dropshipper</span>
            <span class="sub for-retailer"
              >Join our network of retailers and start selling today!
            </span>
          </h1>
        </div>

        <div class="auth-box">
          <div class="errorMessage-container" v-if="showAuthError">
            <div class="material-icons error-icon">cancel</div>
            <div class="error-message">
              Ops! something happened
              <span>{{ errorMessage }}</span>
            </div>
          </div>
          <div class="form-body">
            <div class="form">
              <CustomInput
                type="email"
                id="email"
                label="Email"
                v-model="register.email"
                :required="true"
              />
              <CustomPasswordInput
                type="password"
                id="password"
                label="Password"
                v-model="register.password"
              />

              <div class="d-flex mb-12">
                <label class="custom-checkbox-2">
                  <input
                    type="checkbox"
                    v-model="termsAndConditionsAccecpted"
                    @click="
                      termsAndConditionsAccecpted = !termsAndConditionsAccecpted
                    "
                  />

                  <span class="checkmark-2"> </span>
                </label>

                <span class="accept">
                  I accept the
                  <router-link :to="'/terms'" target="_blank">
                    <span class="terms_condition"> terms & conditions</span>
                  </router-link>

                  and the
                  <router-link :to="'/privacypolicy'" target="_blank">
                    <span class="terms_condition"> privacy policy</span>
                  </router-link>
                </span>
              </div>
              <button
                :disabled="!allFilledAreValid"
                :style="{
                  cursor: !allFilledAreValid ? 'not-allowed' : 'pointer',
                }"
                class="vgang-button form-button"
                @click="handleRegister"
              >
                Create Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// Components
import CustomInput from "@/components/CustomInput.vue";
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";
import buttonLink from "@/components/bases/ButtonLink.vue";
// Models
import User from "@/models/user";

// Services
import { uuid } from "vue-uuid";
import CommonData from "@/services/CommonData";
import { setVariable, getVariable } from "@/utils/conditionalStoreVariables";

export default {
  metaInfo: {
    title: "vgang|register-retailer",
    meta: [
      { name: "description", content: "Do you want to be a retailer?click me" },
    ],
  },
  data() {
    return {
      userType: "",
      showAuthError: false,
      allFilledAreValid: false,
      errorMessage: "",
      user: new User("", ""),
      uuid: uuid.v1(),
      register: {
        email: "",
        password: "",
        DeviceId: this.$uuid.v4(),
        userType: "retailer",
        service: "vgang",
      },
      termsAndConditionsAccecpted: false,
    };
  },
  components: {
    CustomInput,
    CustomPasswordInput,
    buttonLink,
  },
  watch: {
    register: {
      deep: true, // Enable deep watching
      handler(newValue, oldValue) {
        if (this.isValidEmail(newValue.email) && newValue.password) {
          this.allFilledAreValid = true;
        } else {
          this.allFilledAreValid = false;
        }
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.checkRole();
    }
  },
  methods: {
    isValidEmail(email) {
      // Regular expression for basic email validation
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      return emailPattern.test(email);
    },
    checkRole() {
      const user = getVariable("user");
      if (user) {
        CommonData.getCurrentUser()
          .then(function (response) {
            this.userType = response.data.role;
            setVariable("role", this.userType);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        return;
      }
    },
    handleRegister() {
      if (!this.termsAndConditionsAccecpted) {
        this.showAuthError = true;
        this.errorMessage =
          "You need to accept the terms & conditions and privacy policy.";
        setTimeout(() => {
          this.showAuthError = false;
        }, 10000);
        return;
      }
      if (this.allFilledAreValid) {
        this.message = "";
        this.submitted = true;
        this.$store.dispatch("auth/register", this.register).then(
          (data) => {
            // console.log(data);
            this.successful = true;
            this.$router.push({ name: "connectShop" });
            this.$router.push({ name: "Retailer" });
            this.showAuthError = false;
          },
          (error) => {
            this.successful = false;
            this.showAuthError = true;
            this.errorMessage = "Email or Password is wrong";
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  font-size: 14px;
}
.checkmark-2 {
  top: 0;
  &:hover {
    text-decoration: underline;
    color: #008f4a;
  }
}
.terms_condition {
  font-weight: 600;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: #008f4a;
  }
}
.accept {
  color: #101828;
  font-weight: 600;
  font-size: 14px;
}
.formbox {
  //width: 100%;
  @media (max-width: 400px) {
    width: 300px;
  }
}
</style>
