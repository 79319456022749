<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="custom-label"
      :class="hasError ? 'hasError' : ''"
      >{{ label }}</label
    >
    <div class="input-wrapper">
      <input
        :type="newType"
        :name="id"
        :id="id"
        :value="value"
        required="required"
        :placeholder="placeholder ? placeholder : ''"
        class="custom-input"
        :class="{
          hasError: hasError,

          'mb--30': label == 'Your New Password',
        }"
        @input="updateValue($event.target.value)"
        @keyup="$emit('check-input')"
      />
      <img
        src="@/assets/img/inputs/eye.png"
        alt="eye"
        class="password-show"
        @click="changeType"
      />
      <span class="input-hint" :class="hasError ? 'hasError' : ''">{{
        errorMessage
      }}</span>
      <span class="password-change-hint" v-if="label == 'Your New Password'"
        >The password must contain at least 8 characters.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomPasswordInput",
  props: ["id", "label", "icon", "type", "value", "placeholder"],
  data() {
    return {
      errorMessage: "",
      hasError: false,
      newType: "text",
    };
  },
  created() {
    this.newType = this.type;
  },
  methods: {
    changeType() {
      if (this.newType == "text") {
        this.newType = "password";
      } else {
        this.newType = "text";
      }
    },
    updateValue(value) {
      this.$emit("input", value);
    },
    getInputIcon(icon) {
      var InputIcon = require.context("@/assets/img/inputs/", false, /\.png$/);
      return InputIcon("./" + icon + ".png");
    },
  },
};
</script>

<style lang="scss">
.password-change-hint {
  position: absolute;
  bottom: 1px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435160;
}
</style>
