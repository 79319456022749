<template>
  <div :class="registerationSteps ? 'md--1-3' : ''">
    <label
      v-if="label"
      :for="id"
      class="custom-label"
      :class="hasError ? 'hasError' : ''"
      >{{ label }}</label
    >
    <span class="discription">{{ discription }}</span>
    <div
      class="input-wrapper"
      :class="{
        hasInlineButton: hasInlineButton,
      }"
    >
      <div v-if="connect" class="prefix">
        <span class="border">
          {{ prefix }}
        </span>
      </div>
      <input
        v-if="icon"
        :type="type"
        :name="id"
        :id="id"
        :value="value"
        :required="required"
        :placeholder="placeholder ? placeholder : ''"
        class="custom-input"
        :class="{
          hasError: hasError,
          disabled: disabled,
          hasInlineButton: hasInlineButton,
        }"
        @input="updateValue($event.target.value), checkForContent($event)"
        @keyup="$emit('check-input')"
        :maxlength="maxlength ? maxlength : ''"
        :disabled="disabled"
      />
      <input
        v-else-if="prefix"
        :type="type"
        :name="id"
        :id="id"
        :value="value"
        :required="required"
        :placeholder="placeholder ? placeholder : ''"
        class="custom-input without-icon"
        :class="{
          hasError: hasError,
          disabled: disabled,
          hasInlineButton: hasInlineButton,
        }"
        @input="updateValue($event.target.value), checkForContent($event)"
        @keyup="$emit('check-input')"
        :maxlength="maxlength ? maxlength : ''"
        :disabled="disabled"
      />
      <input
        v-else
        :type="type"
        :name="id"
        :id="id"
        :value="value"
        :required="required"
        :placeholder="placeholder ? placeholder : ''"
        class="custom-input"
        :class="{
          hasError: hasError,
          disabled: disabled,
          hasInlineButton: hasInlineButton,
        }"
        @input="updateValue($event.target.value), checkForContent($event)"
        @keyup="$emit('check-input')"
        :maxlength="maxlength ? maxlength : ''"
        :disabled="disabled"
      /><span class="fixed-value">
        {{ fixedValue }}
      </span>
      <span
        class="input-hint"
        v-if="hasError"
        :class="hasError ? 'hasError' : ''"
        >{{ errorMessage }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalInput",
  props: [
    "id",
    "label",
    "icon",
    "type",
    "value",
    "placeholder",
    "registerationSteps",
    "fixedValue",
    "maxlength",
    "disabled",
    "hasInlineButton",
    "required",
    "discription",
    "prefix",
    "connect",
  ],
  data() {
    return {
      hasError: false,
      errorMessage: "",
    };
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    getInputIcon(icon) {
      var InputIcon = require.context("@/assets/img/inputs/", false, /\.png$/);
      return InputIcon("./" + icon + ".png");
    },
    checkForContent(e) {
      if (this.required) {
        let val = e.target.value;
        if (this.id === "phonenumber" && !/^[+]*[0-9]{0,15}$/.test(val)) {
          this.hasError = true;
          this.errorMessage = "Cellphone is not correct";
        } else if (
          this.id === "websiteURL" &&
          !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(
            val
          )
        ) {
          this.hasError = true;
          this.errorMessage = "Website is not correct";
        } else if (
          this.id === "email" &&
          !/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            val
          )
        ) {
          this.hasError = true;
          this.errorMessage = "Email is not correct";
        } else if (e.target.value.length == 0) {
          this.hasError = true;
          this.errorMessage = "Field is required";
        } else {
          this.hasError = false;
          this.errorMessage = "";
        }
      }
    },
  },
};
</script>

<style>
.discription {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  text-align: left;
  display: flex;
}

.border {
  margin-right: 12px;
  border: 1px solid #ffffff !important;
  margin-top: 8px;
  display: inline-block;
}
.hello {
  margin-right: 12px;
}
</style>
