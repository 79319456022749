var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.registerationSteps ? 'md--1-3' : ''},[(_vm.label)?_c('label',{staticClass:"custom-label",class:_vm.hasError ? 'hasError' : '',attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('span',{staticClass:"discription"},[_vm._v(_vm._s(_vm.discription))]),_c('div',{staticClass:"input-wrapper",class:{
      hasInlineButton: _vm.hasInlineButton,
    }},[(_vm.connect)?_c('div',{staticClass:"prefix"},[_c('span',{staticClass:"border"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")])]):_vm._e(),(_vm.icon)?_c('input',{staticClass:"custom-input",class:{
        hasError: _vm.hasError,
        disabled: _vm.disabled,
        hasInlineButton: _vm.hasInlineButton,
      },attrs:{"type":_vm.type,"name":_vm.id,"id":_vm.id,"required":_vm.required,"placeholder":_vm.placeholder ? _vm.placeholder : '',"maxlength":_vm.maxlength ? _vm.maxlength : '',"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){_vm.updateValue($event.target.value), _vm.checkForContent($event)},"keyup":function($event){return _vm.$emit('check-input')}}}):(_vm.prefix)?_c('input',{staticClass:"custom-input without-icon",class:{
        hasError: _vm.hasError,
        disabled: _vm.disabled,
        hasInlineButton: _vm.hasInlineButton,
      },attrs:{"type":_vm.type,"name":_vm.id,"id":_vm.id,"required":_vm.required,"placeholder":_vm.placeholder ? _vm.placeholder : '',"maxlength":_vm.maxlength ? _vm.maxlength : '',"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){_vm.updateValue($event.target.value), _vm.checkForContent($event)},"keyup":function($event){return _vm.$emit('check-input')}}}):_c('input',{staticClass:"custom-input",class:{
        hasError: _vm.hasError,
        disabled: _vm.disabled,
        hasInlineButton: _vm.hasInlineButton,
      },attrs:{"type":_vm.type,"name":_vm.id,"id":_vm.id,"required":_vm.required,"placeholder":_vm.placeholder ? _vm.placeholder : '',"maxlength":_vm.maxlength ? _vm.maxlength : '',"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){_vm.updateValue($event.target.value), _vm.checkForContent($event)},"keyup":function($event){return _vm.$emit('check-input')}}}),_c('span',{staticClass:"fixed-value"},[_vm._v(" "+_vm._s(_vm.fixedValue)+" ")]),(_vm.hasError)?_c('span',{staticClass:"input-hint",class:_vm.hasError ? 'hasError' : ''},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }